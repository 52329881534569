

















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import TrackingHelper from '@/shared/helpers/tracking';
import { namespace } from 'vuex-class';
import hubspotService from '@/services/hubspot';
declare const window: any;

const AuthModule = namespace('auth');

@Component({
  components: {},
})
export default class CommunityPage extends Vue {
  @AuthModule.State loggedUser!: any;
  localJquery: any;
  localHub: any;
  created() {
    const scripts = hubspotService.mountedHubScript(this.loggedUser);
    this.localHub = scripts[0];
    this.localJquery = scripts[1];
  }
  beforeDestroy() {
    this.localJquery.parentElement.removeChild(this.localJquery);
    this.localHub.parentElement.removeChild(this.localHub);
  }
  toggleDrawer() {
    const $el: Element = this.$refs['drawer'] as Element;
    if ($el.classList.contains('show')) {
      $el.classList.remove('show');
    } else {
      $el.classList.add('show');
      TrackingHelper.trackCommunityOpenForm();
    }
  }
}
